<template>
  <div class="PeopleCenter">
    <div class="PeopleCenter_h">
      <div class="PeopleCenter_top" @click="userClick">
        <div class="avatar_box">
          <van-image
            class="avatar"
            fit="cover"
            :src="avatarImg ? avatarImg : require('@/assets/img/main.jpg')"
          />
        </div>

        <div class="personal_info">
          <div class="personal_info_name">
            <h3>{{ name || userPhone }}</h3>
            <div class="grade">
              <div class="grade_icon">
                <van-icon :name="require('@/assets/img/grade_logo.png')" />
              </div>

              <span class="grade_num">{{ garden }}</span>
            </div>
          </div>
          <p class="personal_info_company">
            {{ company || "请填写您的公司名称" }}
          </p>
        </div>
        <van-icon name="arrow" color="#aeaaaa" />
      </div>
      <div class="PeopleCenter_main">
        <div class="PeopleCenter_main_box" @click="toolClick">
          <van-icon class="icon_list" name="qr" />
          <div class="main_list">
            <span class="PeopleCenter_main_box_text">实用工具</span>
            <van-icon name="arrow" color="#aeaaaa" />
          </div>
        </div>
        <div
          class="PeopleCenter_main_box"
          @click="$router.push('/peopleCenter/apply')"
        >
          <van-icon
            class="icon_list"
            :name="require('@/assets/img/PeopleCenter_logo_01.png')"
          />
          <div class="main_list">
            <span class="PeopleCenter_main_box_text">我的申请</span>
            <van-icon name="arrow" color="#aeaaaa" />
          </div>
        </div>
        <div class="PeopleCenter_main_box" @click="$router.push('/signIn')">
          <van-icon
            class="icon_list"
            :name="require('@/assets/img/PeopleCenter_logo_02.png')"
          />
          <div class="main_list">
            <span class="PeopleCenter_main_box_text">我的成长值</span>
            <van-icon name="arrow" color="#aeaaaa" />
          </div>
        </div>
        <div
          class="PeopleCenter_main_box"
          @click="$router.push('/signIn/GrowthValueUse')"
        >
          <van-icon
            class="icon_list"
            :name="require('@/assets/img/PeopleCenter_logo_03.png')"
          />
          <div class="main_list">
            <span class="PeopleCenter_main_box_text">成长值使用记录</span>
            <van-icon name="arrow" color="#aeaaaa" />
          </div>
        </div>
      </div>
      <div class="PeopleCenter_bottom">
        <div class="PeopleCenter_main_box" @click="userClick('setUp')">
          <div class="main_list">
            <van-icon
              class="icon_list"
              :name="require('@/assets/img/PeopleCenter_logo_04.png')"
            />
            <span class="PeopleCenter_main_box_text">设置</span>
          </div>
          <div><van-icon name="arrow" color="#aeaaaa" /></div>
        </div>
      </div>
    </div>
    <copyrightIp />
  </div>
</template>
<script>
import { Icon, Image as VanImage } from "vant";
import {
  oauthLogin,
  findUserByOpenId,
  getSpecificUrlByType,
} from "@/api/PeopleCenter";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    copyrightIp,
  },
  data() {
    return {
      avatarImg: "", // 头像
      name: "", // 名字
      userPhone: "", // 手机号
      garden: "", // 等级
      company: "", //公司
      userInfo: {}, //用户信息
      toolUrl: "", //实用工具url
    };
  },
  created() {
    let openId = localStorage.getItem("code") || "";
    findUserByOpenId({ openId }).then((res) => {
      this.avatarImg = res.data.data.wxHeadimgurl;
      this.name = res.data.data.wxNickname;
      this.userPhone = res.data.data.userPhone;
      this.garden = res.data.data.garden;
      this.company = res.data.data.userCompany;
      this.userInfo = res.data.data;
    });
  },
  mounted() {
    getSpecificUrlByType({ type: 2 }).then((res) => {
      this.toolUrl = res.data.data;
    });
  },
  methods: {
    toolClick() {
      window.location.href = this.toolUrl;
    },
    // 个人信息
    userClick(type) {
      if (type == "setUp") {
        this.$router.push({
          path: "/peopleCenter/personalInfo",
          query: {
            type,
          },
        });
      } else {
        this.$router.push({
          path: "/peopleCenter/personalInfo",
        });
      }
    },
    getOauthLogin(code) {
      oauthLogin({ code }).then((res) => {
        if (res.data.data == 102) {
          findUserByOpenId().then((res) => {});
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.PeopleCenter {
  height: 100%;
  .PeopleCenter_h {
    min-height: calc(100% - 44px);
  }
  .PeopleCenter_top {
    width: 100%;

    display: flex;
    padding: 32px 15px;
    justify-content: space-between;
    align-items: center;
    background: #fff;

    .avatar_box {
      width: 66px;
      height: 66px;
      background: rgba(61, 141, 247, 0.2);
      border-radius: 9px;
      display: flex;
      justify-content: center; //子元素水平居中
      align-items: center; //子元素垂直居中
      .avatar {
        width: 62px;
        height: 62px;
        border-radius: 9px;
        overflow: hidden;
        // box-shadow: 0 0 5px 2px lightblue;
      }
    }

    .personal_info {
      flex: 1;
      padding-left: 10px;
      .personal_info_name {
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        h3 {
          font-weight: bold;
          padding-right: 7px;
          font-size: 19px;
          color: #081222;
        }
        .grade {
          position: relative;
          display: flex;
          align-items: center;
          &::before {
            content: "";
            width: 35px;
            height: 17px;
            background-image: linear-gradient(to right, #fdf8f1, #ffeed4);
            border-radius: 5px 3px 3px 5px;
            position: absolute;
            top: 2px;
            left: 8px;
          }
          .grade_icon {
            ::v-deep .van-icon {
              width: 20px;
              height: 20px;
              color: #f4bd34;
              // font-size: 15px;
              position: relative;
              z-index: 10;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .grade_num {
            color: #966322;
            font-size: 12px;
            padding: 0 5px;
            font-weight: bold;
            position: relative;
            z-index: 20;
            // background-image: linear-gradient(to right, #fdf8f1, #ffeed4);
            // color: #966322;
            // font-size: 12px;
            // display: inline-block;
            // line-height: 15px;
            // font-weight: bold;
            // position: absolute;
            // right: -22px;
            // top: 1px;
            // padding: 0px 5px 0 18px;
            // border-radius: 5px 0 0 5px;
          }
        }
      }
      .personal_info_company {
        font-size: 15px;
        // font-weight: bold;
        color: #737373;
      }
    }
  }
  .PeopleCenter_main {
    width: 100%;
    background: #fff;
    margin: 10px 0;
    padding: 20px 10px 0;
    .PeopleCenter_main_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      font-weight: bold;

      .icon_list {
        width: 20px;
        height: 20px;
        font-size: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .main_list {
        flex: 1;
        border-bottom: 1px solid #eee9e9;
        padding-bottom: 15px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          color: #081222;
          font-weight: normal;
        }
      }

      &:nth-last-child(1) {
        border-bottom: 0;
      }
      .PeopleCenter_main_box_text {
        padding-left: 8px;
      }
    }
  }
  .PeopleCenter_bottom {
    width: 100%;
    background: #fff;
    margin: 10px 0;
    padding: 15px 10px 0;
    .PeopleCenter_main_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #eee9e9;
      padding-bottom: 15px;
      margin-bottom: 15px;
      .main_list {
        display: flex;
        align-items: center;
        span {
          color: #081222;
          font-weight: normal;
        }
      }
      .icon_list {
        width: 20px;
        height: 20px;
        img {
          width: 20px;
          height: 20px;
        }
      }
      &:nth-last-child(1) {
        border-bottom: 0;
      }
      .PeopleCenter_main_box_text {
        padding-left: 8px;
      }
    }
  }
}
</style>
